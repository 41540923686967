import React from "react"

import { FormattedMessage, injectIntl, navigate } from "gatsby-plugin-intl"

import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Herofooter from "../components/Herofooter"

import {
  FaCheckSquare,
  FaUser,
  FaChartBar,
  FaMoneyBill,
  FaPuzzlePiece,
  FaFileInvoice,
  FaThumbsUp,
  FaPaperPlane,
  FaFileAlt,
  FaShoppingCart,
  FaWarehouse,
  FaUserFriends,
  FaShareAlt,
  FaCommentDots,
  FaFileContract,
} from "react-icons/fa"

import ImageBackgroundSvg from "../images/frontend/background-default.svg"
import ImagePurchasingProcess2Img from "../images/frontend/purchasing_process2.png"
import ImageGrowBusinessImg from "../images/frontend/grow_business.png"
import ImagePurchasePortal2Img from "../images/frontend/purchase_portal2.png"
import ImageScreensMixImg from "../images/frontend/screens_mix.png"
import ImageDesignProcessImg from "../images/frontend/design_process.png"

import "../styles/features.scss"

const FeaturesPage = ({ intl }) => (
  <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "SEO.LANDING.FEATURES.TITLE" })}
        description={intl.formatMessage({ id: "SEO.LANDING.FEATURES.DESCRIPTION" })}
        keywords={intl.formatMessage({ id: "SEO.LANDING.FEATURES.KEYWORDS" })}
      />
    <div id="landing-features">
      <div className="tpnet-main-content">
        <tpnet-content>
          <article className="tpnet-article">
            <article className="tpnet-article-inner">
              <div className="tpnet-article-body">
                <main>
                  <div className="bg-desktop" aria-hidden="true">
                    <img
                      src={ImageBackgroundSvg}
                      alt="presentation"
                      aria-hidden="true"
                    />
                  </div>

                  <section className="landing-header">
                    <div className="landing-header__nacho" aria-hidden="true">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="3463.97px"
                        height="3947.35px"
                        viewBox="0 0 3463.97 3947.35"
                        preserveAspectRatio="xMinYMin"
                      >
                        <defs>
                          <clipPath id="landing-header-nacho_svg__clip-path">
                            <path
                              className="landing-header-nacho_svg__cls-1"
                              d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
                            ></path>
                          </clipPath>
                          <clipPath id="landing-header-nacho_svg__clip-path-2">
                            <path
                              fill="none"
                              d="M-5206.6-3363.17h4882v3709h-4882z"
                            ></path>
                          </clipPath>
                          <clipPath id="landing-header-nacho_svg__clip-path-3">
                            <path
                              className="landing-header-nacho_svg__cls-1"
                              d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
                            ></path>
                          </clipPath>
                        </defs>
                        <g
                          clipPath="url(#landing-header-nacho_svg__clip-path)"
                          id="landing-header-nacho_svg__header-nacho"
                        >
                          <g clipPath="url(#landing-header-nacho_svg__clip-path-2)">
                            <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
                          </g>
                        </g>
                        <g
                          clipPath="url(#landing-header-nacho_svg__clip-path-3)"
                          id="landing-header-nacho_svg__landing-header-nacho"
                        >
                          <path
                            transform="rotate(-22 1175.988 2165.688)"
                            id="landing-header-background"
                            fill="currentColor"
                            d="M-530.36 155.86h3412.69v4019.63H-530.36z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <header className="landing-header__text">
                      <div className="landing-header__inner-wrapper">
                        <tpnet-heading className="light">
                          <h1 className="no-link">
                            <FormattedMessage id="LANDING.PAGE_FEATURES.HEADER1" />
                          </h1>
                        </tpnet-heading>
                        <tpnet-heading className="light">
                          <h2>
                            <FormattedMessage id="LANDING.PAGE_FEATURES.HEADER2" />
                          </h2>
                        </tpnet-heading>
                      </div>
                    </header>
                  </section>

                  <div className="content-wrapper">
                    <section className="three-column">
                      <div className="three-column__wrapper">
                        <div className="three-column__column">
                          <div className="three-column__column__image-link">
                            <a className="cta-link">
                              <img
                                src={ImagePurchasingProcess2Img}
                                alt="Manage purchasing process"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <tpnet-heading>
                            <h5 className="no-link">
                              <FormattedMessage id="LANDING.PAGE_FEATURES.SECTION0_CELL1" />
                            </h5>
                          </tpnet-heading>
                        </div>
                        <div className="three-column__column">
                          <div className="three-column__column__image-link">
                            <a className="cta-link">
                              <img
                                src={ImageGrowBusinessImg}
                                alt="Grow your business"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <tpnet-heading>
                            <h5 className="no-link">
                              <FormattedMessage id="LANDING.PAGE_FEATURES.SECTION0_CELL2" />
                            </h5>
                          </tpnet-heading>
                        </div>
                        <div className="three-column__column">
                          <div className="three-column__column__image-link">
                            <a className="cta-link">
                              <img
                                src={ImagePurchasePortal2Img}
                                alt="Explore the purchasing portal"
                                aria-hidden="true"
                              />
                            </a>
                          </div>
                          <tpnet-heading>
                            <h5 className="no-link">
                              <FormattedMessage id="LANDING.PAGE_FEATURES.SECTION0_CELL3" />
                            </h5>
                          </tpnet-heading>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="card-wrapper">
                      <div className="row">
                        <div className="col">
                          <div className="card">
                            <div className="icon-wrapper">
                              <FaShoppingCart />
                            </div>
                            <h3>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL1_HEAD"
                              />
                            </h3>
                            <p>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL1_DESC"
                              />
                            </p>
                          </div>
                        </div>

                        <div className="col">
                          <div className="card">
                            <div className="icon-wrapper">
                              <FaWarehouse />
                            </div>
                            <h3>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL2_HEAD"
                              />
                            </h3>
                            <p>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL2_DESC"
                              />
                            </p>
                          </div>
                        </div>

                        <div className="col">
                          <div className="card">
                            <div className="icon-wrapper">
                              <FaUserFriends />
                            </div>
                            <h3>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL3_HEAD"
                              />
                            </h3>
                            <p>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL3_DESC"
                              />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="card">
                            <div className="icon-wrapper">
                              <FaFileContract />
                            </div>
                            <h3>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL4_HEAD"
                              />
                            </h3>
                            <p>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL4_DESC"
                              />
                            </p>
                          </div>
                        </div>

                        <div className="col">
                          <div className="card">
                            <div className="icon-wrapper">
                              <FaShareAlt />
                            </div>
                            <h3>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL5_HEAD"
                              />
                            </h3>
                            <p>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL5_DESC"
                              />
                            </p>
                          </div>
                        </div>

                        <div className="col">
                          <div className="card">
                            <div className="icon-wrapper">
                              <FaCommentDots />
                            </div>
                            <h3>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL6_HEAD"
                              />
                            </h3>
                            <p>
                              <FormattedMessage
                                id="LANDING.PAGE_FEATURES.SECTION1_CELL6_DESC"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="wall-wrapper">
                      <h2>
                        <FormattedMessage id="LANDING.PAGE_FEATURES.PANEL1_HEAD" />
                      </h2>
                      <h3>
                        <FormattedMessage id="LANDING.PAGE_FEATURES.PANEL1_DESC" />
                      </h3>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="section-feat-left-wrapper">
                      <div className="row">
                        <div className="col">
                          <ul className="product-cols__list" role="list">
                            <li role="listitem">
                              <div className="product-icon">
                                <FaFileAlt />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL1_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL1_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaPaperPlane />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL2_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL2_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaThumbsUp />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL3_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL3_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaFileInvoice />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL4_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION2_CELL4_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="col">
                          <img
                            src={ImageScreensMixImg}
                            alt="Talepnet screens"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="wall-wrapper">
                      <h2>
                        <FormattedMessage id="LANDING.PAGE_FEATURES.PANEL2_HEAD" />
                      </h2>
                      <h3>
                        <FormattedMessage id="LANDING.PAGE_FEATURES.PANEL2_DESC" />
                      </h3>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="section-feat-left-wrapper">
                      <div className="row">
                        <div className="col">
                          <img
                            src={ImageDesignProcessImg}
                            alt="Talepnet design process"
                            aria-hidden="true"
                          />
                        </div>

                        <div className="col">
                          <ul className="product-cols__list" role="list">
                            <li role="listitem">
                              <div className="product-icon">
                                <FaPuzzlePiece />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL1_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL1_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaMoneyBill />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL2_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL1_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaChartBar />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL3_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL3_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaUser />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL4_HEAD"
                                  />
                                </h3>

                                <p className="product-cols__description">
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION3_CELL4_DESC"
                                  />
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="wall-wrapper">
                      <h2>
                        <FormattedMessage id="LANDING.PAGE_FEATURES.PANEL3_HEAD" />
                      </h2>
                      <h3>
                        <FormattedMessage id="LANDING.PAGE_FEATURES.PANEL3_DESC" />
                      </h3>
                    </section>
                  </div>

                  <div className="content-wrapper">
                    <section className="section-feat-left-wrapper simple-wrapper">
                      <div className="row">
                        <div className="col">
                          <h2>
                            <FormattedMessage id="LANDING.PAGE_FEATURES.SUPPLIERS_PORTAL" />
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <ul className="product-cols__list" role="list">
                            <li role="listitem">
                              <div className="product-icon">
                                <FaCheckSquare />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION4_CELL1"
                                  />
                                </h3>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaCheckSquare />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION4_CELL2"
                                  />
                                </h3>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="col">
                          <ul className="product-cols__list" role="list">
                            <li role="listitem">
                              <div className="product-icon">
                                <FaCheckSquare />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION4_CELL3"
                                  />
                                </h3>
                              </div>
                            </li>
                            <li role="listitem">
                              <div className="product-icon">
                                <FaCheckSquare />
                              </div>

                              <div className="product-cols__text">
                                <h3>
                                  <FormattedMessage
                                    id="LANDING.PAGE_FEATURES.SECTION4_CELL4"
                                  />
                                </h3>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>
                  </div>

                  <Herofooter />
                </main>
              </div>
            </article>
          </article>
        </tpnet-content>
      </div>
    </div>
  </Layout>
)

export default injectIntl(FeaturesPage)
